import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import { Panel, PanelGroup } from "rsuite";
import { Carousel } from "rsuite";
import { Notification, toaster } from "rsuite";
import { Loader } from "rsuite";
import { Badge } from "rsuite";

import "./App.css";
import FooterLogo from './footerLogo.png';
import './fonts/font/fonts.css';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-family: "DigitalDisco";
  padding: 10px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  // background-color: black;

  background-image: url(config/images/MintBtns.svg);
  background-repeat: no-repeat;
  background-color: currentcolor;
  background-size: 170px;
  background-position: center;

  padding: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  color: white;
  width: 220px;
  height: 70px;
  cursor: pointer;

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;

export const StyledButton2 = styled.button`
  font-family: "DigitalDisco";
  padding: 10px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  // background-color: black;

  background-image: url(config/images/notWLbtn.svg);
  background-repeat: no-repeat;
  background-color: currentcolor;
  background-size: 170px;
  background-position: center;

  padding: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  color: white;
  width: 220px;
  height: 70px;
  cursor: not-allowed;

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;
export const CTNButton = styled.button`
  font-family: "DigitalDisco";
  padding: 10px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  background-image: url(config/images/connectBtn.svg);
  background-repeat: no-repeat;
  background-color: currentcolor;
  background-position: center;
  padding: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  color: white;
  width: 215px;
  height: 64px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-image: url(config/images/connectBtnHover.svg);
    background-repeat: no-repeat;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;

export const Maxbtn = styled.button`
  font-family: "DigitalDisco";
  font-size: 0.75rem;
  border-radius: 10px;
  background-color: #f48c2c;
  font-weight: bold;
  color: white;
  width: 80px;
  height: 30px;
  cursor: pointer;
  letter-spacing: 2px;
  :hover {
    color: black;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: black;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: silver;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10%;
  width: 100%;
`;

export const ResponsiveWrapper = styled.div`
  display: block;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: center;
  margin: auto;
  width: 40%;
  border: 2px solid white;
  border-radius: 40px;
  background: linear-gradient(90deg, #dfdfdf 10%, #dfdfdf 93%);
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (min-width: 425px) and (max-width: 888px){
    width: 70%;
  }
  @media (max-width: 424px) {
    width: 90%;
  }
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;

  background-image: url(config/images/headerBG.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  filter: drop-shadow(0px 8px 25px #0B0B0B);

  // background-color: #252525;
  @media (max-width: 1024px) {
    background-size: auto 100%;
  }
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    // max-height: 220px;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 400px;
  margin 0 auto;
  padding-top: 120px;
  margin-left: 180px;
  @media (max-width: 900px) {
    display: inline;
    width: 350px;
    margin 0 auto;
    margin-top: -15px;
    margin-left: 195px;
  }

  @media (max-width: 766px) {
    display: inline;
    width: 350px;
    margin 0 auto;
    margin-top: -130px;
    margin-left: 0px;
  }

  @media (max-width: 565px) {
    display: inline;
    width: 300px;
    margin 0 auto;
    padding-top: 0px;
    margin-top: -15px;
    margin-left: 0px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 220px;
  margin: 0 auto;
  display: block;

  border-radius: 25px;
  border: 5px solid black;

  @media (min-width: 900px) {
    width: 220px;
  }
  @media (max-width: 1000px) {
    width: 220px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
  @media (max-width: 424px) {
    width: 200px;
  }
  @media (max-width: 300px) {
    width: 150px;
  }
`;

export const Styledroad = styled.img`
  width: 100%;
  border-radius: 5px;
  transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 220px;
    height: 220px;
  }
  @media (min-width: 1000px) {
    width: 220px;
    height: 220px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const StyledLink = styled.a`
  color: red;
  text-decoration: none;
  font-family: "DigitalDisco";
  font-size: 30px;
`;

export const WalletBox = styled.div`
  text-decoration: none;
  border-radius: 10px;
  background-image: url(config/images/walletBG.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  // border: 2px solid white;
  background-color: transparent;
  //padding: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 766px) {
    margin: 0 auto;
  }
  @media (max-width: 565px) {
    margin-top: 20px;
    margin: 0 auto;
  }

  : hover {
    background-image: url(config/images/walletBGHover.svg);
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [tokens, settokens] = useState(1);
  const [brd, setbrd] = useState("2px solid #FFFFFF");
  const [bxsh, setbxsh] = useState("0px 0px 3px 0px #FFFFFF");
  const [DOT, setDOT] = useState("red");
  const [whitelisted, setWhitelisted] = useState(false);
  const [isMinted, setMinted] = useState(false);
  const [type, setType] = React.useState("info");
  const [placement, setPlacement] = React.useState("topStart");
  const errmessage = (
    <Notification type={"error"} header={"error"} closable>
      Sorry, not in the mood please try again later.
    </Notification>
  );
  const txmessage = (
    <Notification type={"success"} header={"success"} closable>
      Congrats, you have lasted 3 seconds! It's yours now.
    </Notification>
  );
  const mntmessage = (
    <Notification type={"info"} header={"success"} closable>
      <Loader />Shhhhh... seeding in Progress....
    </Notification>
  );
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    DISPLAY_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    MAX_PER_TX: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    Telegram: "",
    Discord: "",
    Twitter: "",
    SHOW_BACKGROUND: true,
    costForRabbits: null,
    costForBlack: null,
    mintForRabbits: null,
    mintForBlack: null,
    getMintedForRabbits: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.DISPLAY_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    // setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setFeedback(`Shhhhh... seeding in Progress....`);
    setClaimingNft(true);
    setbrd("2px solid yellow");
    setbxsh("0px 0px 3px 0px yellow");
    toaster.push(mntmessage, { placement });
    blockchain.smartContract.methods
      .mintForBreedingPass(1)
      // .mint(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        // setFeedback("Sorry, you missed a hole please try again.");
        // setFeedback("Sorry, you are fucked. please try again.");
        // setFeedback("Sorry, you fucked a wrong moon. please try again.");
        setFeedback("Sorry, you come before 3 secs. please try again.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement });
        setbrd("2px solid red");
        setbxsh("0px 0px 3px 0px red");
      })
      .then((receipt) => {
        console.log(receipt);
        // setFeedback(
        //   `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        // );
        setFeedback(
            `Congrats, you have lasted 3 seconds! It's yours now.`
          );
        toaster.push(txmessage, { placement });
        setbrd("2px solid green");
        setbxsh("0px 0px 3px 0px green");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };
  const claimNFTs5 = () => {
    let cost = CONFIG.DISPLAY_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    // setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setFeedback(`Shhhhh... seeding in Progress....`);
    setClaimingNft(true);
    setbrd("2px solid yellow");
    setbxsh("0px 0px 3px 0px yellow");
    toaster.push(mntmessage, { placement });
    blockchain.smartContract.methods
      .mintForBreedingPass(5)
      // .mint(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        // setFeedback("Sorry, you missed a hole please try again.");
        // setFeedback("Sorry, you are fucked. please try again.");
        // setFeedback("Sorry, you fucked a wrong moon. please try again.");
        setFeedback("Sorry, you come before 3 secs. please try again.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement });
        setbrd("2px solid red");
        setbxsh("0px 0px 3px 0px red");
      })
      .then((receipt) => {
        console.log(receipt);
        // setFeedback(
        //   `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        // );
        setFeedback(
            `Congrats, you have lasted 3 seconds! It's yours now.`
          );
        toaster.push(txmessage, { placement });
        setbrd("2px solid green");
        setbxsh("0px 0px 3px 0px green");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };
  const claimNFTs10 = () => {
    let cost = CONFIG.DISPLAY_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    // setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setFeedback(`Shhhhh... seeding in Progress....`);
    setClaimingNft(true);
    setbrd("2px solid yellow");
    setbxsh("0px 0px 3px 0px yellow");
    toaster.push(mntmessage, { placement });
    blockchain.smartContract.methods
      .mintForBreedingPass(10)
      // .mint(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        // setFeedback("Sorry, you missed a hole please try again.");
        // setFeedback("Sorry, you are fucked. please try again.");
        // setFeedback("Sorry, you fucked a wrong moon. please try again.");
        setFeedback("Sorry, you come before 3 secs. please try again.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement });
        setbrd("2px solid red");
        setbxsh("0px 0px 3px 0px red");
      })
      .then((receipt) => {
        console.log(receipt);
        // setFeedback(
        //   `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        // );
        setFeedback(
            `Congrats, you have lasted 3 seconds! It's yours now.`
          );
        toaster.push(txmessage, { placement });
        setbrd("2px solid green");
        setbxsh("0px 0px 3px 0px green");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };
  const claimNFTs20 = () => {
    let cost = CONFIG.DISPLAY_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    // setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setFeedback(`Shhhhh... seeding in Progress....`);
    setClaimingNft(true);
    setbrd("2px solid yellow");
    setbxsh("0px 0px 3px 0px yellow");
    toaster.push(mntmessage, { placement });
    blockchain.smartContract.methods
      .mintForBreedingPass(20)
      // .mint(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        // setFeedback("Sorry, you missed a hole please try again.");
        // setFeedback("Sorry, you are fucked. please try again.");
        // setFeedback("Sorry, you fucked a wrong moon. please try again.");
        setFeedback("Sorry, you come before 3 secs. please try again.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement });
        setbrd("2px solid red");
        setbxsh("0px 0px 3px 0px red");
      })
      .then((receipt) => {
        console.log(receipt);
        // setFeedback(
        //   `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        // );
        setFeedback(
            `Congrats, you have lasted 3 seconds! It's yours now.`
          );
        toaster.push(txmessage, { placement });
        setbrd("2px solid green");
        setbxsh("0px 0px 3px 0px green");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };
  // const whitelistedForRabbits = async () => {
  //   const ret = await blockchain.smartContract.methods
  //     .getWhitelistedForRabbits(blockchain.account)
  //     .call();
  //   console.log(`result : ${ret}`);
  //   setWhitelisted(ret);
  // };

  const getMintedForRabbits = async () => {
    const ret = await blockchain.smartContract.methods
      .getMintedForRabbits(blockchain.account)
      .call();
    console.log(`result : ${ret}`);
    setMinted(ret);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(
        blockchain.account.substring(0, 4) +
          "..." +
          blockchain.account.substring(38, 42)
      );
      setDOT("green");
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    // whitelistedForRabbits();
    getMintedForRabbits();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        // ai={"center"}
        style={{ backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/back.png" : null}
      >
        <ResponsiveWrapperHeader>
          <LogoDiv>
          <a href="https://www.moonrabbits.wtf">
            <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
          </a>
          </LogoDiv>

          <s.HeaderDiv>
            <WalletBox>
              {blockchain.account !== "" ? (
                <>
                  <s.TextSubTitle style={{ fontSize: "14px", color: "black" }}>
                    {/* <Badge color={DOT} /> {walletAddress} */}{walletAddress}
                  </s.TextSubTitle>
                </>
              ) : null}
            </WalletBox>
          </s.HeaderDiv>
        </ResponsiveWrapperHeader>
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        {/* =================== HEADER =================== */}
        {/* ===================== END ==================== */}
        {/* =================== HEADER =================== */}

          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.TextTitle>GET YOUR Breeding Pass!</s.TextTitle>
          </s.Container>

          <s.SpacerSmall />

          <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          {whitelisted === false ? (
            <div className="mint-box-in">
              <s.SpacerMedium />
              <s.SpacerMedium />
              <StyledImg src={"/config/images/BreedingPass.gif"} alt="image" />
              <s.SpacerSmall />
              <s.Container flex={1} jc={"center"} ai={"center"}>
                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                  <>
                    {/* <s.TextSub
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontFamily: "DigitalDisco",
                      }}
                    >
                      The sale has ended.
                      The sun is up. Time to get out of the BED.
                    </s.TextSub> */}
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontFamily: "DigitalDisco",
                      }}
                    >
                      The Moon had risen!
                      <br />Time to hop into the BED. 
                      <br/>

                      Your {CONFIG.NFT_NAME}<br/>waiting for yours at
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink
                      target={"_blank"}
                      href={CONFIG.MARKETPLACE_LINK}
                    >
                      {CONFIG.MARKETPLACE}
                    </StyledLink>
                  </>
                ) : (
                  <>
                  <s.SpacerSmall />
                    <s.TextSub2
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontFamily: "coder",
                      }}
                    >
                      {data.totalSupply} | {CONFIG.MAX_SUPPLY}
                    </s.TextSub2>
                    {/* <s.TextTotal
                  style={{
                    background: "white",
                    borderRadius: 5,
                    padding: 8,
                    color: "black",
                  }}
                >
                  Mint Price&emsp;&emsp;&emsp;&emsp;&emsp;{CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL} + gas
                </s.TextTotal> */}
                    {/* <s.SpacerMedium /> */}
                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                      <>
                        <s.Container ai={"center"} jc={"center"}>
                          <s.SpacerSmall />
                          <CTNButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          ></CTNButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontFamily: "coder",
                                  fontSize: 20,
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      </>
                    ) : (
                      <>
                      {isMinted === false ? (<>
                        <s.SpacerXSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"column"}>
                          <div className="mintbtn1">
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? (
                              <Loader speed="fast" content="Minting..." />
                            ) : (
                              "MINT 1EA"
                            )}
                          </StyledButton>
        <s.SpacerSmall />
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs5();
                              getData();
                            }}
                          >
                            {claimingNft ? (
                              <Loader speed="fast" content="Minting..." />
                            ) : (
                              "MINT 5EA"
                            )}
                          </StyledButton></div>
        <s.SpacerSmall />
                        <div className="mintbtn1">
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs10();
                              getData();
                            }}
                          >
                            {claimingNft ? (
                              <Loader speed="fast" content="Minting..." />
                            ) : (
                              "MINT 10EA"
                            )}
                          </StyledButton>
        <s.SpacerSmall />
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs20();
                              getData();
                            }}
                          >
                            {claimingNft ? (
                              <Loader speed="fast" content="Minting..." />
                            ) : (
                              "MINT 20EA"
                            )}
                          </StyledButton>
                        </div>
                        </s.Container>
                        <s.SpacerXSmall />

                        <s.TextTotal style={{ color: "black", fontFamily: "DigitalDisco" }}>
                          Total =&ensp;
                          {(CONFIG.DISPLAY_COST * tokens)
                            .toString()
                            .substring(0, 6)}{" "}
                          {CONFIG.NETWORK.SYMBOL} + gas
                        </s.TextTotal>
                        <s.TextSubTitle
                          style={{ fontSize: 15, fontFamily: "DigitalDisco" }}
                        >
                          Max {CONFIG.MAX_PER_TX} PER WALLET
                        </s.TextSubTitle>

                        <s.SpacerXSmall />
                        <s.TextSubTitle
                          style={{ textAlign: "center", fontSize: "1rem", color: "red"}}
                        >
                          {feedback}
                        </s.TextSubTitle></>
                    ) : <>
                      <s.SpacerXSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"column"}>
                        <StyledButton2
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            getData();
                          }}
                        >
                          {claimingNft ? (
                            <Loader speed="fast" content="Minting..." />
                          ) : (
                            "MINTED"
                          )}
                        </StyledButton2>
                      </s.Container>
                      <s.SpacerXSmall />

                      <s.TextTotal style={{ color: "black", fontFamily: "DigitalDisco" }}>
                        Total =&ensp;
                        {(CONFIG.DISPLAY_COST * tokens)
                          .toString()
                          .substring(0, 6)}{" "}
                        {CONFIG.NETWORK.SYMBOL} + gas
                      </s.TextTotal>
                      <s.TextSubTitle
                        style={{ fontSize: 15, fontFamily: "DigitalDisco" }}
                      >
                        Max {CONFIG.MAX_PER_TX} PER WALLET
                      </s.TextSubTitle>

                      <s.SpacerXSmall />
                      <s.TextSubTitle
                        style={{ textAlign: "center", fontSize: "1rem", color: "red"}}
                      >
                        {feedback}
                      </s.TextSubTitle></>}
                      </>

                    )}
                  </>
                )}
                <s.SpacerMedium />
              </s.Container>
              <s.SpacerLarge />
            </div>
            ) : (
              
            // ========== NOT WL ==========
            // ========== NOT WL ==========
            // ========== NOT WL ==========
            // ========== NOT WL ==========
            // ========== NOT WL ==========

            <div className="mint-box-in">
            <s.SpacerMedium />
            <s.SpacerMedium />
            <StyledImg src={"/config/images/RandomGIF.gif"} alt="image" />
            <s.SpacerSmall />
            <s.Container flex={1} jc={"center"} ai={"center"}>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  {/* <s.TextSub
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "DigitalDisco",
                    }}
                  >
                    The sale has ended.
                    The sun is up. Time to get out of the BED. 
                  </s.TextSub> */}
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "DigitalDisco",
                    }}
                  >
                    The Moon had risen!
                    <br />Time to hop into the BED. 
                    <br/>

                    Your {CONFIG.NFT_NAME}<br/>waiting for yours at
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledLink
                    target={"_blank"}
                    href={CONFIG.MARKETPLACE_LINK}
                  >
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                <s.SpacerSmall />
                  <s.TextSub2
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "coder",
                    }}
                  >
                    {data.totalSupply} | {CONFIG.MAX_SUPPLY}
                  </s.TextSub2>
                  {/* <s.TextTotal
                style={{
                  background: "white",
                  borderRadius: 5,
                  padding: 8,
                  color: "black",
                }}
              >
                Mint Price&emsp;&emsp;&emsp;&emsp;&emsp;{CONFIG.DISPLAY_COST}{" "}
                {CONFIG.NETWORK.SYMBOL} + gas
              </s.TextTotal> */}
                  {/* <s.SpacerMedium /> */}
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <>
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerSmall /><s.SpacerSmall />
                        <CTNButton
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        ></CTNButton>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontFamily: "coder",
                                fontSize: 20,
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    </>
                  ) : (
                    <>
                      {/* <s.AmountContainer
                        style={{
                          border: brd,
                          boxShadow: bxsh,
                        }}
                      >
                        <StyledRoundButton
                          style={{ lineHeight: 0.4 }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            decrementtokens();
                          }}
                        >
                          -
                        </StyledRoundButton>
                        <s.TEXTamount>
                          &ensp;&ensp;&ensp;&ensp;{tokens}
                          &ensp;&ensp;&ensp;&ensp;
                        </s.TEXTamount>
                        <StyledRoundButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            incrementtokens();
                          }}
                        >
                          +
                        </StyledRoundButton>
                      </s.AmountContainer> */}
                      {/* <Maxbtn
                    onClick={(e) => {
                      e.preventDefault();
                      settokens(CONFIG.MAX_PER_TX);
                    }}
                  >
                    SetMax
                  </Maxbtn> */}
                      <s.SpacerXSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"column"}>
                        <StyledButton2
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            getData();
                          }}
                        >
                          {claimingNft ? (
                            <Loader speed="fast" content="Minting..." />
                          ) : (
                            "Not Whitelisted"
                          )}
                        </StyledButton2>
                      </s.Container>
                      <s.SpacerXSmall />

                      {/* ===== INFO TEXT ===== */}
                      <s.TextTotal style={{ color: "black", fontFamily: "DigitalDisco" }}>
                        Total =&ensp;
                        {(CONFIG.DISPLAY_COST * tokens)
                          .toString()
                          .substring(0, 6)}{" "}
                        {CONFIG.NETWORK.SYMBOL} + gas
                      </s.TextTotal>
                      <s.TextSubTitle
                        style={{ fontSize: 15, fontFamily: "DigitalDisco" }}
                      >
                        Max {CONFIG.MAX_PER_TX} PER WALLET
                      </s.TextSubTitle>
                      
                      {/* ===== INFO TEXT END ===== */}

                      <s.SpacerXSmall />
                      <s.TextSubTitle
                        style={{ textAlign: "center", fontSize: "1rem", color: "red"}}
                      >
                        Wallet not whitelisted.
                      <br />
                      Please wait for out
                      <br />
                      next NFT Mint date.
                      </s.TextSubTitle>
                    </>
                  )}
                </>
              )}
              <s.SpacerMedium />
            </s.Container>
            <s.SpacerLarge />
          </div>
            )}

          </ResponsiveWrapper>


          <s.SpacerLarge />
        <s.SecContainer id="">
          <s.SpacerLarge />
          <img className="FooterLogo" src={FooterLogo} alt="png" />
          <s.TextP>© 2022 {CONFIG.NFT_NAME}</s.TextP>
        </s.SecContainer>
      </s.Container>
    </s.Screen>
  );
}

export default App;
